<template>
  <div class="electricAddEdit">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false"
               @close="closeDio('dioform')">
      <div class="contDio">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="130px" size="small">
          <el-form-item label="场所:" prop="homeId">
            <el-select v-model="inform.homeId" placeholder="请选择场所">
              <el-option v-for="(item,index) in dicObj.homeList" :key="index" :label="item.homeName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称:" prop="boardName">
            <el-input v-model="inform.boardName" placeholder="请输入设备名称"></el-input>
          </el-form-item>
          <el-form-item label="设备标识:" prop="boardId">
            <el-input v-model="inform.boardId" placeholder="请输入设备标识"></el-input>
          </el-form-item>
          <el-form-item label="Web地址:" prop="boardIp">
            <el-input v-model="inform.boardIp" placeholder="请输入Web IP地址"></el-input>
          </el-form-item>
          <el-form-item label="设备厂商:" prop="productType">
            <el-select v-model="inform.productType" placeholder="请选择设备厂商">
              <el-option v-for="(item,index) in dicObj.productData"
                         :key="index"
                         :label="item.dictName"
                         :value="Number(item.dictValue)"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="授权码code:" prop="grantCode">
            <el-input v-model="inform.grantCode" placeholder="请输入授权码code"></el-input>
          </el-form-item>
          <el-form-item label="授权码secret:" prop="grantSecret">
            <el-input v-model="inform.grantSecret" placeholder="请输入授权码secret"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {apiUrl} from '@/assets/js/api'
import qs from 'qs'
import {delChildren} from '@/utils/utils'
import {wgs84togcj02, gcj02towgs84} from '@/utils/positionUtil';//百度坐标与gps坐标转换方法
var vm;
export default {
  props: ['dicObj'],
  data() {
    const checkIPCode = (rule, value, callback) => {
      if (/^(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])$/.test(value) == false) {
        callback(new Error("请输入正确的ip地址"));
      } else {
        callback();
      }
    }
    return {
      isDio: false,//弹窗类型
      dioTit: '',//弹窗标题
      dioType: '',//弹窗类型
      dioWidth: '',//弹窗宽度
      //form信息
      inform: {
        id: '',
        boardName: '',//设备名称
        boardIp: '',
        boardId: '',//设备编码
        homeId: '',//场所id
        deviceId: '',
        productType: '',
        grantCode: '',
        grantSecret: ''
      },

      rules: {
        homeId: [
          {required: true, message: '请选择场所', trigger: "change"}
        ],
        productType: [
          {required: true, message: '请选择设备厂商', trigger: "change"}
        ],
        boardName: [
          {required: true, message: '请输入设备名称', trigger: "blur"}
        ],
        boardIp: [
          {required: true, message: '请输入正确的ip地址', trigger: "blur", validator: checkIPCode}
        ],
        boardId: [
          {required: true, message: '请输入设备标识', trigger: 'blur'}
        ]
      },
    }
  },
  created() {
    vm = this;
  },
  mounted() {

  },
  //方法
  methods: {
    //初始化
    init(type, item) {
      vm.dioWidth = '640px';
      vm.isDio = true;
      vm.dioType = type;
      vm.$nextTick(() => {
        if (type == 'add') {
          vm.dioTit = '新增边缘计算';
          vm.inform.id = 0;
          vm.inform.deviceId = 0;
        } else if (type == 'edit') {
          vm.dioTit = '编辑边缘计算';
          vm.inform = {
            id: item.id,
            deviceId: item.deviceId,
            boardName: item.boardName,//设备名称
            boardIp: item.boardIp,
            boardId: item.boardId,//设备编码
            homeId: item.homeId,//场所id
            productType: item.productType,
            grantCode: item.grantCode,
            grantSecret: item.grantSecret
          };
        }
      })
    },
    //校验form
    checkForm(formEl) {
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if (vm.dioType == 'add') {
            vm.addDevice();
          } else if (vm.dioType == 'edit') {
            vm.updateDevice();
          }
        } else {
          return false
        }
      })
    },

    //关闭弹窗
    closeDio(formEl) {
      vm.$refs[formEl].resetFields()
      vm.inform = {
        id: '',
        deviceId: '',
        boardName: '',//设备名称
        boardIp: '',
        boardId: '',//设备编码
        homeId: '',//场所id
        productType: '',
        grantCode: '',
        grantSecret: ''
      }
      vm.isDio = false
    },
    //新增
    async addDevice() {
      let dataObj = {
        'id': vm.inform.id,
        'deviceId': vm.inform.deviceId,
        'boardName': vm.inform.boardName,//设备名称
        'boardIp': vm.inform.boardIp,
        'boardId': vm.inform.boardId,//设备编码
        'homeId': vm.inform.homeId,//场所id
        'productType': vm.inform.productType,
        'grantCode': vm.inform.grantCode,
        'grantSecret': vm.inform.grantSecret
      }
      const res = await apiUrl.addDeviceBox(dataObj)
      if (res?.code == 200) {
        vm.$message.success('创建成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      } else {
        vm.$message.error(res.message)
      }
    },
    //修改
    async updateDevice() {
      let dataObj = {
        'id': vm.inform.id,
        'deviceId': vm.inform.deviceId,
        'boardName': vm.inform.boardName,//设备名称
        'boardIp': vm.inform.boardIp,
        'boardId': vm.inform.boardId,//设备编码
        'homeId': vm.inform.homeId,//场所id
        'productType': vm.inform.productType,
        'grantCode': vm.inform.grantCode,
        'grantSecret': vm.inform.grantSecret
      }
      const res = await apiUrl.addDeviceBox(dataObj)
      if (res?.code == 200) {
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      } else {
        vm.$message.error(res.message)
      }
    }
  }
}
</script>
<style lang='scss'>
.electricAddEdit {
  .contDio {
    max-height: 500px;
    overflow: auto;
  }

  .uploader {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      &:hover {
        border-color: #409EFF;
      }

      .iconJia {
        font-size: 28px;
        color: #8c939d;
        width: 88px;
        height: 116px;
        line-height: 116px;
        text-align: center;
      }

      .img {
        width: 88px;
        height: 116px;
        display: block;
      }
    }
  }
}
</style>