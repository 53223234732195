<template>
  <div class="electricAddEdit">
    <el-dialog title="设置数据指向" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false"
               @close="closeDio('dioform1')">
      <div class="contDio">
        <el-form ref="dioform1" :rules="rules" :model="inform" label-width="130px" size="small">
          <el-divider content-position="left">MQTT连接配置</el-divider>
          <el-form-item label="IP配置:">
            <el-input v-model="inform.mqttIp"
                      placeholder="配置IP地址" style="width: 75%;"></el-input>
          </el-form-item>
          <el-form-item label="端口配置:">
            <el-input v-model="inform.mqttPort"
                      placeholder="配置端口" style="width: 75%;"></el-input>
          </el-form-item>
          <el-form-item label="连接账户:">
            <el-input v-model="inform.mqttUser" placeholder="请输入mqtt账户" style="width: 75%;"></el-input>
          </el-form-item>
          <el-form-item label="连接密码:">
            <el-input v-model="inform.mqttPwd" placeholder="请输入mqtt密码" style="width: 75%;"></el-input>
          </el-form-item>
          <el-form-item label="上报周期/s:">
            <el-input-number v-model="inform.mqttCycle" placeholder="上报周期" style="width: 30%;" :min="60"
                             :max="3600"></el-input-number>
          </el-form-item>
          <el-divider content-position="left">UDP连接配置</el-divider>
          <el-form-item label="IP配置:">
            <el-input v-model="inform.udpIp"
                      placeholder="配置IP地址" style="width: 75%;"></el-input>
          </el-form-item>
          <el-form-item label="上报周期/s:">
            <el-input-number v-model="inform.udpCycle" placeholder="上报周期" style="width: 30%;" :min="60"
                             :max="3600"></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform1')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform1')" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {apiUrl} from '@/assets/js/api'
import qs from 'qs'
import {delChildren} from '@/utils/utils'

var vm;
export default {
  props: ['dicObj'],
  data() {
    const checkIPCode = (rule, value, callback) => {
      if ((/^(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])$/.test(value) == false)
          && value != '') {
        callback(new Error("请输入正确的ip地址"));
      } else {
        callback();
      }
    }
    const checkPortCode = (rule, value, callback) => {
      //两位到五位的数值
      if (/^\d{2,5}$/.test(value) == false && value != '') {
        callback(new Error("请输入正确的端口号"));
      } else {
        callback();
      }
    }
    return {
      isDio: false,//弹窗类型
      dioTit: '',//弹窗标题
      dioType: '',//弹窗类型
      dioWidth: '',//弹窗宽度


      deviceType: '',
      //form信息
      inform: {
        mqttIp: '',
        mqttPort: 1883,
        mqttUser: 'admin',
        mqttPwd: 'public',
        mqttCycle: 60,
        udpIp: '',
        udpCycle: 60,
        targetDeviceIds: []
      },

      rules: {
        mqttIp: [
          {required: false, message: '请输入正确的ip地址', trigger: "blur", validator: checkIPCode},
        ],
        mqttPort: [
          {required: false, message: '请输入正确的端口号', trigger: "blur", validator: checkPortCode}
        ],
        mqttUser: [
          {required: false, message: '请输入mqtt账户', trigger: "blur"}
        ],
        mqttPwd: [
          {required: false, message: '请输入mqtt密码', trigger: "blur"}
        ],
        udpIp: [
          {required: false, message: '请输入正确的ip地址', trigger: "blur", validator: checkIPCode},
        ],
        udpPort: [
          {required: false, message: '请输入正确的端口号', trigger: "blur", validator: checkPortCode}
        ]
      },
    }
  },
  created() {
    vm = this;
  },
  mounted() {

  },
  //方法
  methods: {
    //初始化
    init(type, targetIdArr) {
      vm.dioType = type
      vm.dioWidth = '640px'
      vm.isDio = true
      vm.$nextTick(() => {
        vm.inform = {
          mqttIp: '',
          mqttPort: 1883,
          mqttUser: 'admin',
          mqttPwd: 'public',
          mqttCycle: 60,
          udpIp: '',
          udpPort: 5918,
          udpCycle: 60,
          targetDeviceIds: targetIdArr
        }
      })
    },
    //校验form
    checkForm(formEl) {
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          vm.doSubmit();
        } else {
          return false
        }
      })
    },

    //关闭弹窗
    closeDio(formEl) {
      vm.$refs[formEl].resetFields()
      vm.inform = {}
      vm.isDio = false
    },

    //新增
    async doSubmit() {
      console.log(vm.inform);
      const res = await apiUrl.deviceNetConfig(vm.inform)
      if (res?.code == 200) {
        vm.$message.success('配置完成！')
        vm.$emit('netConfigSucc');
        vm.closeDio('dioform1')
      } else {
        vm.$message.error(res.message)
      }
    },

  }
}
</script>
<style lang='scss'>
.electricAddEdit {
  .contDio {
    max-height: 500px;
    overflow: auto;

    .el-divider {
      background-color: #28417a !important;;
    }

    .el-divider__text {
      background-color: #12305a !important;
      color: #fff !important;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .uploader {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      &:hover {
        border-color: #409EFF;
      }

      .iconJia {
        font-size: 28px;
        color: #8c939d;
        width: 88px;
        height: 116px;
        line-height: 116px;
        text-align: center;
      }

      .img {
        width: 88px;
        height: 116px;
        display: block;
      }
    }
  }
}
</style>