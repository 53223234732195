<template>
  <div class="electricDetail">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false"
               @close="closeDio('dioform')">
      <div>
        <el-tabs v-model="tabsActive" @tab-click="tabsClick" type="border-card">
          <el-tab-pane label="设备信息" name="1">
            <el-form class="contDio" ref="dioform" :model="inform" label-width="100px" size="small">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="">
                    <el-image fit="contain" :src="getIcon()"></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="设备名称:">{{ inform.boardName }}</el-form-item>
                </el-col>
                <el-col :span="16">
                  <el-form-item label="设备编号:">{{ inform.boardId }}</el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="配置地址:">{{ inform.boardIp }}</el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="安装场所:">{{ inform.homeName }}</el-form-item>
                </el-col>
                <el-col :span="12" v-if="inform.status!=null">
                  <el-form-item label="在线状态:">
                    <span
                        :class="['status',{'success':inform.status=='1','info':inform.status=='0'}]">
                        <span v-if="inform.status=='1'">在线</span>
                        <span v-else>离线</span>
                      </span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="TPU信息:">{{ inform.tpuInfo }}</el-form-item>
                </el-col>
                <template v-if="inform.attributes && inform.attributes.length > 0">
                  <el-col :span="12" v-for="(item,index) in inform.attributes" :key="getUUID()">
                    <el-form-item :label="`${item.name}:`">{{ item.value }}{{ item.unit }}</el-form-item>
                  </el-col>
                </template>
              </el-row>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="视频通道" name="2">
            <el-table class="tableCont" :data="inform.channels" stripe :height="400" style="width:100%">
              <el-table-column type="index" label="序号" width="60"/>
              <el-table-column prop="mediaName" label="通道名称"/>
                <el-table-column prop="mediaUrl" label="通道URL" show-overflow-tooltip/>
                <el-table-column prop="subId" label="通道号配置"/>
                <el-table-column prop="label" label="状态"/>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {apiUrl, globalStr} from '@/assets/js/api'
import qs from 'qs'
import {delChildren, getUUID, formatTime} from '@/utils/utils'
let d_icon1 = require('@/assets/images/device-box.png');
var vm;
export default {
  props: ['dicObj'],
  data() {
    return {
      isDio: false,//弹窗类型
      dioTit: '',//弹窗标题
      dioType: '',//弹窗类型
      dioWidth: '',//弹窗宽度

      tabsActive: '1',//tabs

      //form信息
      inform: {

      },

    }
  },
  created() {
    vm = this
  },
  filters: {
    //状态过滤器
    statesFilter(val, type) {
      //在线状态
      if (type == '1') {
        let result = vm.dicObj.statusData.filter(item => item.dictValue == val)
        return result[0].dictName
      }
    },
  },
  //方法
  methods: {
    //初始化
    init(type, item) {
      vm.dioType = type
      vm.dioWidth = '840px'
      vm.isDio = true

      vm.$nextTick(() => {
        if (type == 'detail') {
          vm.dioTit = '详情';
          vm.inform = {};
          vm.getDevice(item.id);
        }
      })
    },
    getIcon() {
      return d_icon1;
    },
    //获取uuid
    getUUID() {
      return getUUID()
    },

    //tabs切换
    tabsClick(tab, event) {

    },
    async getDevice(id) {
      let dataObj = {
        'id': id
      }
      const res = await apiUrl.deviceBoxDetail(qs.stringify(dataObj))
      if (res?.code == 200) {
        vm.inform = res.data
      } else {
        vm.$message.error(res.message)
      }
    },

    //关闭弹窗
    closeDio(formEl) {
      vm.inform = {

      }
      vm.tabsActive = '1'
      vm.isDio = false
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
  }
}
</script>
<style lang='scss'>
.electricDetail {
  .contDio {
    max-height: 500px;
    overflow: auto;

    .el-form-item--small.el-form-item {
      margin-bottom: 0px;
    }

    .el-image {
      width: 100px;
      height: 150px;
    }

    .name {
      padding-bottom: 10px;
      border-bottom: 1px solid #ddd;
      font-size: 16px;
      color: #fff;
    }
  }
}
</style>